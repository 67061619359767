import { IFormation } from "../components/CalendarFormations/formations/formations";

export const isDateCurrent = (date: Date) => {
    let currentDate = new Date();
    if (date >= currentDate) {
        return true;
    } else {
        return false;
    }
};

export const containsFormation = (formations: IFormation[]) => {
    var valableFormations = [];
    formations.forEach((element: IFormation) => {
        if (isDateCurrent(element.date)) {
            valableFormations.push(element);
        }
    });
    if (valableFormations.length > 0) {
        return true;
    } else {
        return false;
    }
};

export const getMonthStringEquivalent = (month: number) => {
    switch (month) {
        case 0:
            return "Janvier";
        case 1:
            return "Février";
        case 2:
            return "Mars";
        case 3:
            return "Avril";
        case 4:
            return "Mai";
        case 5:
            return "Juin";
        case 6:
            return "Juillet";
        case 7:
            return "Août";
        case 8:
            return "Septembre";
        case 9:
            return "Octobre";
        case 10:
            return "Novembre";
        case 11:
            return "Décembre";
        default:
            return "";
    }
};

export const sortFormationsByYear = (formations: IFormation[]) => {
    debugger;
    const sortedFormations = [];
    const years: any[] = [];
    for (let i = 0; i < formations.length; i++) {
        const formation = formations[i];
        const year = formation.date.getFullYear();
        if (!years.includes(year)) {
            years.push(year);
        }
    }
    for (let i = 0; i < years.length; i++) {
        const year = years[i];
        const yearFormations = [];
        for (let j = 0; j < formations.length; j++) {
            const formation = formations[j];
            if (formation.date.getFullYear() === year) {
                yearFormations.push(formation);
            }
        }
        sortedFormations.push({ year: year, formations: yearFormations });
    }
    const finalArr = [];
    const months = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
    ];

    const monthArray: any[] = [];
    for (let i = 0; i < years.length; i++) {
        const year = years[i];
        const monthArray = [];
        for (let i = 0; i < months.length; i++) {
            const month = months[i];
            const monthFormations = [];
            for (let j = 0; j < sortedFormations.length; j++) {
                const yearFormation = sortedFormations[j].formations;
                for (let k = 0; k < yearFormation.length; k++) {
                    const current = yearFormation[k];
                    let currentMonth = getMonthStringEquivalent(current.date.getMonth());
                    let date = new Date();
                    let arrayHoursMin = current.endTime.split(":");
                    date.setHours(parseInt(arrayHoursMin[0]), parseInt(arrayHoursMin[1]), 0, 0);
                    current.date.setHours(
                        parseInt(arrayHoursMin[0]),
                        parseInt(arrayHoursMin[1]),
                        0,
                        0
                    );
                    if (
                        currentMonth === month &&
                        current.date.getFullYear() === year &&
                        current.date >= date
                    ) {
                        monthFormations.push(current);
                    }
                }
            }
            if (monthFormations.length > 0)
                monthArray.push({ month: month, formations: monthFormations });
        }
        let monthWithoutFormation = monthArray.filter((m) => m.formations.length === 0).length;
        if (monthArray.length > 0 && monthWithoutFormation !== monthArray.length) {
            finalArr.push({ year: year, months: monthArray });
            finalArr.sort((a: any, b: any) => (a.year > b.year ? 1 : -1));
        }
    }
    return finalArr;
};

export const capitalizeFirstLetter = (string: any) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export //function that converts month from string to number
const getMonthNumber = (month: any) => {
    switch (month) {
        case "Janvier":
            return 0;
        case "Février":
            return 1;
        case "Mars":
            return 2;
        case "Avril":
            return 3;
        case "Mai":
            return 4;
        case "Juin":
            return 5;
        case "Juillet":
            return 6;
        case "Août":
            return 7;
        case "Septembre":
            return 8;
        case "Octobre":
            return 9;
        case "Novembre":
            return 10;
        case "Décembre":
            return 11;
        default:
            return 0;
    }
};

//function that gets the month from Date object
export const isMonthCurrent = (month: any) => {
    var date = new Date();
    const currentMonthNumber = date.getMonth();
    const final = getMonthNumber(month.month);
    if (final >= currentMonthNumber) {
        return true;
    } else {
        return false;
    }
};

export const getDayMonth = (date: Date) => {
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("fr-FR", { month: "long" }).format(date);
    return day + " " + month;
};

export const getFormattedDate = (date: Date) => {
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("fr-FR", { month: "long" }).format(date);
    const year = date.getFullYear();
    return day + " " + month + " " + year;
};





